import { GetStaticPaths } from "next"

import { IMegamenuCategory } from "data/contentData/interfaces/IMegamenuCategory"
import { IContainerCard } from "data/contentData/interfaces/mongodb/IContainerCard"
import { IPage } from "data/contentData/interfaces/mongodb/IPage"

import PageComponent from "Components/Layout/PageComponent/PageComponent"

export default function Page({
    page,
    footer,
    megamenu
}: {
    page: IPage
    footer: IContainerCard[]
    megamenu: IMegamenuCategory[]
}) {
    return (
        <PageComponent
            page={page}
            footer={footer}
            megamenu={megamenu}
        />
    )
}

export const getStaticPaths: GetStaticPaths = async () => {
    return {
        paths: ["/"],
        fallback: "blocking" // false or 'blocking'
    }
}

export { getStaticProps } from "Components/Layout/PageComponent/PageComponent"
